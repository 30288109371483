
function akelSignature() {

    const initSignaturePad = (canvasId, options = {}) => {
        try {
            const canvas = document.getElementById(canvasId);
            if (!canvas) throw new Error(`Canvas element with id '${canvasId}' not found.`);
            const ctx = canvas.getContext('2d');
            let isDrawing = false;
            let lastPoint;
            const settings = {
                lineWidth: options.lineWidth || 2,
                strokeStyle: options.strokeStyle || '#000',
                lineCap: options.lineCap || 'round',
                lineJoin: options.lineJoin || 'round'
            };
            Object.assign(ctx, settings);
            const drawLine = (start, end) => {
                ctx.beginPath();
                ctx.moveTo(start.x, start.y);
                ctx.lineTo(end.x, end.y);
                ctx.stroke();
            };
            const getPoint = (e) => {
                const rect = canvas.getBoundingClientRect();
                const clientX = e.clientX || (e.touches && e.touches[0].clientX);
                const clientY = e.clientY || (e.touches && e.touches[0].clientY);
                return {
                    x: clientX - rect.left,
                    y: clientY - rect.top
                };
            };
            const handleStart = (e) => {
                if (e.button === 2) return;
                e.preventDefault();
                isDrawing = true;
                lastPoint = getPoint(e);
            };
            const handleMove = (e) => {
                if (!isDrawing) return;
                e.preventDefault();
                const currentPoint = getPoint(e);
                drawLine(lastPoint, currentPoint);
                lastPoint = currentPoint;
            };
            const handleEnd = (e) => {
                if (!isDrawing) return;
                isDrawing = false;
                e.preventDefault();
                saveSignature();
            };
            const saveSignature = () => {
                const signatureImage = document.getElementById('signature_image');
                if (signatureImage) {
                    signatureImage.value = canvas.toDataURL('image/png');
                } else {
                    console.warn("Element with id 'signature_image' not found. Signature data not saved.");
                }
            };
            const clearSignature = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                const signatureImage = document.getElementById('signature_image');
                if (signatureImage) {
                    signatureImage.value = '';
                }
            };
            const handleRightClick = (e) => {
            /*e.preventDefault();
            clearSignature();*/
            };
            const addEventListeners = () => {
                canvas.addEventListener('mousedown', handleStart);
                canvas.addEventListener('mousemove', handleMove);
                canvas.addEventListener('mouseup', handleEnd);
                canvas.addEventListener('mouseout', handleEnd);
                canvas.addEventListener('touchstart', handleStart);
                canvas.addEventListener('touchmove', handleMove);
                canvas.addEventListener('touchend', handleEnd);
                canvas.addEventListener('contextmenu', handleRightClick);
            };
            const removeEventListeners = () => {
                canvas.removeEventListener('mousedown', handleStart);
                canvas.removeEventListener('mousemove', handleMove);
                canvas.removeEventListener('mouseup', handleEnd);
                canvas.removeEventListener('mouseout', handleEnd);
                canvas.removeEventListener('touchstart', handleStart);
                canvas.removeEventListener('touchmove', handleMove);
                canvas.removeEventListener('touchend', handleEnd);
                canvas.removeEventListener('contextmenu', handleRightClick);
            };
            addEventListeners();
            const clearButton = document.getElementById('clear-signature');
            if (clearButton) {
                clearButton.addEventListener('click', clearSignature);
            } else {
                console.warn("Element with id 'clear-signature' not found. Clear button functionality not added.");
            }
            return {
                clear: clearSignature,
                remove: () => {
                    removeEventListeners();
                    if (clearButton) {
                        clearButton.removeEventListener('click', clearSignature);
                    }
                }
            };
        } catch (error) {
            console.error('Error initializing signature pad:', error);
            return null;
        }
    };
    const signaturePad = initSignaturePad('signature-pad', {
        lineWidth: 2,
        strokeStyle: '#1C39BB'
    });
    const signaturePadsm = initSignaturePad('signature-pad-sm', {
        lineWidth: 2,
        strokeStyle: '#1C39BB'
    });


}
